<template>
  <div class="app-container">
    <eHeader
      v-if="permission.add || permission.search"
      :menus="distors"
      :sup_this="sup_this"
      :query="query"
      search_account
    >
      <el-button
        v-if="permission.add"
        slot="create"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="create"
        >添加供应商账号</el-button
      >
    </eHeader>

    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
    <!--表格渲染-->
    <CommonTable
      height="calc(100vh - 288px)"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      :selection="false"
      @selection-change="handleSelectionChange"
    >
      <!-- 供应商slot -->
      <template #supplierSlot="{ scoped }">
        <span>{{ scoped[scoped.prop] && scoped[scoped.prop].supplierName }}</span>
      </template>
      <!-- 用户角色 -->
      <template #rolesSlot="{ scoped: row }">
        <el-tag
          style="margin: 5px"
          type="primray"
          size="mini"
          :key="id"
          v-for="{ id, roleName } in row[row.prop] || []"
        >
          {{ roleName }}
        </el-tag>
      </template>
      <slot>
        <el-table-column label="操作" width="240px" align="center">
          <template slot-scope="scope">
            <disabledBtn v-if="permission.disabled" :data="scope.row" type="user"></disabledBtn>
            <edit v-if="permission.edit" class="menu-btn-item" :menus="distors" :data="scope.row" :sup_this="sup_this" />
            <updatePass v-if="permission.changePassword" class="menu-btn-item" :data="scope.row" :sup_this="sup_this" />
            <!-- <Assign :menus="menus" :data="scope.row" :sup_this="sup_this" /> -->
            <!--<color-text-btn type="danger"> 禁用 </color-text-btn>-->
          </template>
        </el-table-column>
      </slot>
    </CommonTable>
    <PaginationBar
      :page="page"
      :size="size"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
  </div>
</template>

<script>
import initDataMixin from '@/mixins/initData'
import { getSelectedRoles } from '@/api/role'

import { parseTime } from '@/utils'
import eHeader from '../module/header'
import disabledBtn from '@/views/components/disabledBtn'
import edit from './module/edit'
import updatePass from '../module/updatePass'
import Assign from '../module/assign'
import { cols, permission } from './cols'

export default {
  mixins: [initDataMixin],
  components: { eHeader, edit, updatePass, Assign, disabledBtn },
  data() {
    return {
      cols,
      permission,
      delLoading: false,
      url: '/externaladmin/authService/user/supplierUserList',
      sup_this: this,
      distors: [],
      treeIds: [],
      menus: [],
      method: 'post'
    }
  },
  created() {
    // this.getSelectedRoles()
  },
  methods: {
    parseTime,
    async getSelectedRoles() {
      const { detail = [] } = (await getSelectedRoles()) || {}
      this.menus = detail
    },
    initCallBack() {
      this.treeIds.map((id) => {
        const node = this.$refs.table.table['store'].states.treeData[id]
        node.loaded = false
        node.expanded = false
      })
    },
    create() {
      this.$router.push('/supplier/addSupplier')
    }
  }
}
</script>

<style scoped></style>
