import { checkPermission } from '@/utils'

export const cols = [
  {
    prop: 'accountName',
    label: '登录账号',
    align: 'center'
  },
  {
    prop: 'nickName',
    label: '联系人',
    width: '80',
    align: 'left'
  },
  {
    prop: 'is_active',
    label: '激活状态',
    slotName: 'is_activeName',
    align: 'center'
  },
  {
    prop: 'supplierDTO',
    label: '所属供应商',
    align: 'center',
    slotName: 'supplierSlot'
  },
  {
    prop: 'roleDTOList',
    label: '角色',
    minWidth: 150,
    align: 'left',
    slotName: 'rolesSlot'
  }
]

export const permission = {
  search: checkPermission('externaladmin:supplier:account:search'),
  add: checkPermission('externaladmin:supplier:account:add'),
  disabled: checkPermission('externaladmin:supplier:account:disabled'),
  edit: checkPermission('externaladmin:supplier:account:edit'),
  changePassword: checkPermission('externaladmin:supplier:account:changePassword')
}
