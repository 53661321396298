<template>
  <div class="head-container">
    <div class="inline-block user-component-header">
      <slot name="create" />
      <SaleDateTime class="sale-date-time-wrapper" :time.sync="time" @change="toQuery"></SaleDateTime>
      <el-input
        v-if="search_supplier"
        v-model="query.supplierName"
        clearable
        v-p="['externaladmin:supplier:list:search']"
        placeholder="输入供应商搜索"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="toQuery"
      />
      <el-input
        v-if="search_account"
        v-model="query.accountName"
        clearable
        v-p="['externaladmin:supplier:account:search']"
        placeholder="输入供应商账号搜索"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="toQuery"
      />
    </div>

    <eForm ref="form" v-bind="$attrs" :is-add="true" />
  </div>
</template>

<script>
import SaleDateTime from '@/components/saleDateTime'
import { commonEheaderMixin } from '@/mixins'

import eForm from './form'
// 查询条件
export default {
  mixins: [commonEheaderMixin],
  components: { eForm, SaleDateTime },
  props: {
    search_supplier: {
      type: Boolean,
      default: false
    },
    search_account: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      time: [],
      formField: [
        {
          prop: 'supplier_create_time'
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('GetDic', 'supplier')
  }
}
</script>
<style lang="scss" scoped>
.user-component-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  .sale-date-time-wrapper {
    margin: 0 20px;
  }
}
</style>
